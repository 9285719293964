import axios from 'axios';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../assets/CSS/PlacedOffers.css';
import { jwtToken } from '../Context/DataContext';
import { Spinner, ButtonSpinner } from './Spinner';
import { UserContext } from '../Context/UserContext';
import { TimestampToDate } from '../utils/DateAndTimeUtils';
import swal from 'sweetalert';
import Accordion from 'react-bootstrap/Accordion';

export default function PlacedOffers() {
  const { token } = useContext(jwtToken);
  const { userID } = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [initialLoading, setInitialLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState('');
  const [acceptedOffers, setAcceptedOffers] = useState([]);
  const [newOffers, setNewOffers] = useState([]);
  const [cancelledOffers, setCancelledOffers] = useState([]);
  const [expiredOffers, setExpiredOffers] = useState([]);
  const [rejectedOffers, setRejectedOffers] = useState([]);
  const [purchasedOffers, setPurchasedOffers] = useState([]);

  const fetchPlacedOffers = useCallback(async () => {
    if (token && userID) {
      setInitialLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}placedoffers`,
          { userID }, { headers: { Authorization: `Bearer ${token}` } }
        );
        setData(response.data);
      } catch (error) {
        swal({
          text: 'Failed to retrieve placed offers!',
          icon: 'error',
          dangerMode: true,
        });
      } finally {
        setInitialLoading(false);
      }
    }
  }, [token, userID]);

  useEffect(() => {
    fetchPlacedOffers();
  }, [fetchPlacedOffers]);

  useEffect(() => {
    setAcceptedOffers(data.filter((item) => item.Status === 'Accepted'));
    setNewOffers(data.filter((item) => item.Status === 'New'));
    setCancelledOffers(data.filter((item) => item.Status === 'Cancelled'));
    setExpiredOffers(data.filter((item) => item.Status === 'Expired'));
    setRejectedOffers(data.filter((item) => item.Status === 'Rejected'));
    setPurchasedOffers(data.filter((item) => item.Status === 'Purchased'));
  }, [data]);

  function purchaseDisplay(item) {
    navigate({
      pathname: '/purchase',
      state: {
        plateNumber: item.PlateNumber,
        VIN: item.VIN,
        offerID: item.OfferID,
        amount: item.Amount
      }
    });
  }

  function cancelOffer(item) {
    const offerID = item.OfferID;
    swal({
      title: `Cancel Offer!`,
      text: `Do you want to cancel the offer for the vehicle ${item.PlateNumber || item.VIN}?`,
      icon: "info",
      buttons: {
        cancel: "No",
        defeat: {
          text: 'Yes',
          className: 'request-button' // Add a custom class name for the button
        }
      },
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setCancelLoading(offerID);
          try {
            const cancelResponse = await axios.post(`${process.env.REACT_APP_API_COMMON}canceloffer`, { userID, offerID },
              { headers: { Authorization: `Bearer ${token}` } });
            if (cancelResponse.status === 200) {
              setData(prevData => {
                const index = prevData.findIndex(item => item.OfferID === cancelResponse.data.OfferID);
                if (index !== -1) {
                  const updatedData = [...prevData];
                  updatedData[index] = { ...cancelResponse.data, PlateNumber: prevData[index].PlateNumber, VIN: prevData[index].VIN };
                  return updatedData;
                }
                return prevData;
              });

              swal({
                text: 'Offer cancelled successfully!',
                icon: 'success',
                dangerMode: true,
              });
            }
          } catch (error) {
            swal({
              text: 'Failed to cancel the offer!',
              icon: 'error',
              dangerMode: true,
            });
          } finally {
            setCancelLoading('');
          }
        }
      });
  }

  return (
    <>
      {initialLoading && !cancelLoading ? (<Spinner></Spinner>) : (
        <div className='wrapper p-spacing'>
          <div className="app">
            {/* Form Section*/}
            <div className="container m-spacing" style={{ paddingBottom: "100px" }}>
              <>
                {data.length === 0 ? (<div className="text-center my-5 py-5">No Offers</div>) : (
                  <>
                    <div className="offer-details">
                      <div className="form-group mb-2">
                        <div className="row mb-5">
                          <div className="col mb-2">
                            <input
                              type="text"
                              placeholder="Search from plate number/VIN"
                              className="form-control"
                              onChange={(e) => {
                                setSearchData(e.target.value);
                              }}
                            />
                          </div>
                          <div className="col-3">
                            <button className="btn btn-danger" id="search-button" type="button" style={{ height: '90%' }}>
                              {' '}
                              Search &nbsp;
                              <i className="fa fa-search"></i>
                            </button>
                          </div>
                        </div>

                        <div>
                          <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>Accepted Offers</Accordion.Header>
                              <Accordion.Body>
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '15%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '15%' }}>Created Date</th>
                                      <th style={{ width: '15%' }}>Expire Date</th>
                                      <th style={{ width: '15%' }}>Offered Amount</th>
                                      <th style={{ width: '15%' }}>Status</th>
                                      <th style={{ width: '25%' }}>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {acceptedOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((item, index) => (
                                        <tr key={index}>
                                          <td>
                                            <p className="text-muted mb-0">{item.PlateNumber || item.VIN}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.CreatedAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.ExpireAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">${item.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{item.Status}</p>
                                          </td>
                                          <td className="">
                                            <Button
                                              id="accept-btn"
                                              variant="success"
                                              disabled={
                                                item.Status === 'Accepted'
                                                  ? false
                                                  : true
                                              }
                                              onClick={() => purchaseDisplay(item)}
                                            >
                                              Purchase
                                            </Button>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                              <Accordion.Header>New Offers</Accordion.Header>
                              <Accordion.Body>
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '15%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '15%' }}>Created Date</th>
                                      <th style={{ width: '15%' }}>Expire Date</th>
                                      <th style={{ width: '15%' }}>Offered Amount</th>
                                      <th style={{ width: '15%' }}>Status</th>
                                      <th style={{ width: '25%' }}>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {newOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((item, index) => (
                                        <tr key={index}>
                                          <td>
                                            <p className="text-muted mb-0">{item.PlateNumber || item.VIN}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.CreatedAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.ExpireAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">${item.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{item.Status}</p>
                                          </td>
                                          <td className="">
                                            <Button
                                              id="accept-btn"
                                              variant="danger"
                                              disabled={
                                                (item.Status === 'New' || item.Status === 'Pending') && !cancelLoading
                                                  ? false
                                                  : true
                                              }
                                              onClick={() => cancelOffer(item)}
                                            >
                                              {cancelLoading === item.OfferID ? (
                                                <ButtonSpinner />
                                              ) : (
                                                'Cancel'
                                              )}
                                            </Button>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                              <Accordion.Header>Cancelled Offers</Accordion.Header>
                              <Accordion.Body>
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '20%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '20%' }}>Created Date</th>
                                      <th style={{ width: '20%' }}>Expire Date</th>
                                      <th style={{ width: '20%' }}>Offered Amount</th>
                                      <th style={{ width: '20%' }}>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {cancelledOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((item, index) => (
                                        <tr key={index}>
                                          <td>
                                            <p className="text-muted mb-0">{item.PlateNumber || item.VIN}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.CreatedAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.ExpireAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">${item.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{item.Status}</p>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                              <Accordion.Header>Expired Offers</Accordion.Header>
                              <Accordion.Body>
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '20%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '20%' }}>Created Date</th>
                                      <th style={{ width: '20%' }}>Expire Date</th>
                                      <th style={{ width: '20%' }}>Offered Amount</th>
                                      <th style={{ width: '20%' }}>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {expiredOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((item, index) => (
                                        <tr key={index}>
                                          <td>
                                            <p className="text-muted mb-0">{item.PlateNumber || item.VIN}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.CreatedAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.ExpireAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">${item.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{item.Status}</p>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="4">
                              <Accordion.Header>Rejected Offers</Accordion.Header>
                              <Accordion.Body>
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '20%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '20%' }}>Created Date</th>
                                      <th style={{ width: '20%' }}>Expire Date</th>
                                      <th style={{ width: '20%' }}>Offered Amount</th>
                                      <th style={{ width: '20%' }}>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {rejectedOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((item, index) => (
                                        <tr key={index}>
                                          <td>
                                            <p className="text-muted mb-0">{item.PlateNumber || item.VIN}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.CreatedAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.ExpireAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">${item.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{item.Status}</p>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="5">
                              <Accordion.Header>Purchased Offers</Accordion.Header>
                              <Accordion.Body>
                                <table className="table align-middle mb-0 mt-2 bg-white">
                                  <thead className="bg-light">
                                    <tr>
                                      <th style={{ width: '20%' }}>Plate Number/VIN</th>
                                      <th style={{ width: '20%' }}>Created Date</th>
                                      <th style={{ width: '20%' }}>Expire Date</th>
                                      <th style={{ width: '20%' }}>Offered Amount</th>
                                      <th style={{ width: '20%' }}>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {purchasedOffers
                                      .filter((val) => {
                                        if (!searchData.trim()) {
                                          return val;
                                        } else if (val.PlateNumber?.toLowerCase().includes(searchData.toLocaleLowerCase()) || val.VIN?.toLowerCase().includes(searchData.toLocaleLowerCase())) {
                                          return val;
                                        }
                                        return null;
                                      })
                                      .sort((a, b) => new Date(b.Date) - new Date(a.Date))
                                      .map((item, index) => (
                                        <tr key={index}>
                                          <td>
                                            <p className="text-muted mb-0">{item.PlateNumber || item.VIN}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.CreatedAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{TimestampToDate(item.ExpireAt)}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">${item.Amount}</p>
                                          </td>
                                          <td>
                                            <p className="text-muted mb-0">{item.Status}</p>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
