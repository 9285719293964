import React, { useState, useContext, useEffect } from 'react';
import '../App.css';
import { Button } from './Button';
import '../assets/CSS/HeroSection.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { GlobalContext } from '../Context/GlobalContext';
import MakeModelDropdown from './MakeModelDropdown';
import { UserContext } from '../Context/UserContext';
import swal from 'sweetalert';
import NicknameModal from './NicknameModal';
import { VehicleDataContext } from '../Context/VehicleDataContext';
import SearchContext from '../Context/SearchContext';
import axios from 'axios';
import Card from '@mui/material/Card';
import Cover1 from '../assets/img/Cover1.png';
import Cover2 from '../assets/img/Cover2.png';
import Poster4 from '../assets/img/Poster4.jpeg';
import Poster5 from '../assets/img/Poster5.png';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import FeaturedVehicles from './FeaturedVehicles';
import ImportedVehicles from './ImportedVehicles';
import DiscountIcon from '@mui/icons-material/Discount';
import { TimestampToDate } from '../utils/DateAndTimeUtils';
import Chatbot from './ChatBot';

function HeroSection() {
  const { expiredClaims = [] } = useContext(GlobalContext);
  const { recaptchaToken, setRecaptchaToken } = useContext(UserContext);
  const navigate = useNavigate();
  const [modelMessage, setModelMessage] = useState('');
  const [showFalse, setShowFalse] = useState(false);
  const [isWidgetVisible, setIsWidgetVisible] = useState(true);
  const [plateNumberOrVIN, setPlateNumberOrVIN] = useState('');
  const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;
  const handleCloseFalse = () => setShowFalse(false);
  const { userID, user } = useContext(UserContext);
  const { expiredClaimModalShown, setExpiredClaimModalShown } = useContext(VehicleDataContext);
  const { selectedMake, setSelectedMake, selectedModel, setSelectedModel } = useContext(SearchContext);
  const location = useLocation();
  const [makeArray, setMakeArray] = useState([]);
  const [modelArray, setModelArray] = useState([]);
  const [passPromotions, setPassPromotions] = useState([]);
  const [featuredDealer, setFeaturedDealer] = useState(null);


  useEffect(() => {
    if (expiredClaims.length > 0 && expiredClaimModalShown === 'false') {
      setModelMessage('Claim expired..! Please reclaim');
      setShowFalse({ showFalse: true });
    }
  }, [expiredClaims]);

  function dataSend(e) {
    e.preventDefault();

    if (plateNumberOrVIN) {
      if (plateNumberOrVIN.length > 17 || plateNumberOrVIN.length < 4) {
        swal({
          text: 'Invalid Plate Number or VIN!',
          icon: 'error',
          dangerMode: true,
        });
      } else if (punctuationRegex.test(plateNumberOrVIN)) {
        swal({
          text: 'Invalid Plate Number or VIN!',
          icon: 'error',
          dangerMode: true,
        });
      } else {
        navigate(`/details`, {
          state: { plateNumberOrVIN }
        });
      }
    } else {
      navigate('/advancedSearch', {
        state: { fetchdata: true }
      });
    }
  }

  useEffect(() => {
    const fetchMakeModel = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_SEARCH_API}getmakesmodelsorcombination`, {});
        const makeArray = response.data.makes.map(item => item.make);
        setMakeArray(makeArray);
        const modelArray = response.data.models.map(item => item.model);
        setModelArray(modelArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMakeModel();
  }, [selectedMake, selectedModel]);

  useEffect(() => {
    const fetchPassPromotions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_COMMON}passPromotions`, {}, {});
        const passPromotions = response.data;
        setPassPromotions(passPromotions);
      }
      catch (error) {
        console.log(error);
      }
    };
    fetchPassPromotions();
  }, []);

  useEffect(() => {
    const queryParameters = new URLSearchParams(location.search);
    let plateNumberOrVIN = null;
    let plateOrVINKey = null;

    if (queryParameters.has('plate') || queryParameters.has('vin')) {
      plateOrVINKey = queryParameters.has('plate') ? 'plate' : 'vin';
      plateNumberOrVIN = queryParameters.get(plateOrVINKey);
    }
    let paymentStatus = null;
    if (queryParameters.has('payment') && queryParameters.has('vin')) {
      paymentStatus = queryParameters.get('payment');
      if (paymentStatus === "success") {
        swal({
          text: 'Payment successfull',
          icon: 'success',
          dangerMode: true,
        }).then(() => {
          navigate('/details', {
            state: { plateNumberOrVIN }
          });
        });
      } else if (paymentStatus === "canceled") {
        swal({
          text: 'Payment unsuccessfull',
          icon: 'error',
          dangerMode: true,
        }).then(() => {
          navigate('/details', {
            state: { plateNumberOrVIN }
          });
        });
      }
    } else if (plateOrVINKey) {
      const getDetails = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_COMMON}getvehicledetailsbyplateorvin`, { plateNumberOrVIN });
          if (response.data) {
            navigate('/details', {
              state: { plateNumberOrVIN }
            });
          }
        } catch (error) {
          if (error.response.status === 404) {
            swal({
              text: 'Vehicle not found!',
              icon: 'error',
              dangerMode: true,
            });
          } else {
            swal({
              text: 'Failed to fetch vehicle details!',
              icon: 'error',
              dangerMode: true,
            });
          }
          navigate(`/`);
        }
      };

      getDetails();
    } else {
      navigate(`/`);
    }
  }, [navigate]);

  window.handleCaptchaSuccess = handleCaptchaSuccess;
  function handleCaptchaSuccess(token) {
    setRecaptchaToken(token);
    setIsWidgetVisible(false);
  }
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);


  useEffect(() => {
    let player;

    function onPlayerReady(event) {
      event.target.playVideo();
    }

    function onPlayerStateChange(event) {
      if (event.data === window.YT.PlayerState.ENDED) {
        event.target.seekTo(0);
        event.target.playVideo();
      }
    }

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = function () {
      player = new window.YT.Player('youtubePlayer', {
        height: '315',
        width: '560',
        videoId: '6aFTRNpJgTg',
        playerVars: {
          autoplay: 1,
          mute: 1,
        },
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        }
      });
    };

    return () => {
      if (player) {
        player.destroy();
      }
    };
  }, []); // Run only once on component mount


  function handleCardClick() {
    navigate(`/advancedSearch?trader-no=${featuredDealer.TraderNumber}`);
  }

  useEffect(() => {
    const fetchActiveFeaturedDealer = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_COMMON}getActiveFeaturedDealers`, {});
        setFeaturedDealer(response.data);
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchActiveFeaturedDealer();
  }, []);


  const [isScreenLarge, setIsScreenLarge] = useState(window.innerWidth > 992);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenLarge(window.innerWidth > 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="loginContain">
      <div className="row">
        <h1 className="slogan">
          Cars for Sale NZ
        </h1>
        <h6 className="subSlogan">
          Find Cars Online
        </h6>
      </div>
      <div className="row firstscreen col-12 md-12 midCol">
        <div className="col-sm col-md-12 col-lg-2 imagesz" style={{ whiteSpace: 'nowrap' }}>
          <picture>
            <source media="(min-width: 993px)" srcSet={Poster4} style={{ width: '100%', height: '100vh', margin: '0px 0px 5px 0px', borderRadius: '10px' }} />
            <source media="(max-width: 992px)" srcSet={Cover1} style={{ width: '100%', height: 'auto', margin: '0px 0px 5px 0px', borderRadius: '10px' }} />
            <img src={Cover2} alt="IfItDoesntMatchAnyMedia" />
          </picture>
        </div>
        <div className="col-sm col-md-12 col-lg-8" >
          <div>
            <p className="BlkWhite" style={{ backgroundColor: 'black', color: 'white', textAlign: 'center' }}>
              <marquee direction="left">
                {passPromotions && passPromotions.length > 0 && (
                  <>
                    <DiscountIcon />
                    &nbsp; DEALS: &nbsp;
                    {passPromotions.map((item, index) => (
                      <span key={index}>
                        {item.PromotionName}, Free listing (limited offer, till {TimestampToDate(item.EndTime)}) &emsp;
                      </span>
                    ))}
                    &emsp; | &emsp;
                  </>
                )}
                {(makeArray || modelArray) && <DriveEtaIcon />}
                {makeArray && makeArray.length > 0 && (
                  <>
                    &nbsp; MAKES :&emsp;
                    {makeArray.map((item, index) => (
                      <span key={index}>{item} &emsp;</span>
                    ))}
                  </>
                )}
                {modelArray && modelArray.length > 0 && (
                  <>
                    &emsp; | &emsp; MODELS :&emsp;
                    {modelArray.map((item, index) => (
                      <span key={index}>{item} &emsp;</span>
                    ))}
                  </>
                )}
              </marquee>
            </p>
            <p className="descr">Discover your next vehicle with confidence on Waka Online NZ. Every car featured on our site comes with comprehensive reports covering stolen vehicles, imported damage, and recall status checks. Opt-in for additional peace of mind with money owing checks and a comprehensive vehicle valuation report when the seller chooses to provide them.</p>
            <p className="descr">Explore each listing with complete safety and energy ratings, accompanied by optional details and photos shared by the sellers. Plus, as a Waka Online NZ user, you are eligible for up to a $100 insurance discount through our partnership with Cove Insurance. Visit <a href='https://www.coveinsurance.co.nz/lp/wakaonline/' target='_blank' rel='noreferrer'>here</a> for more details.</p>
            {/* <p className="descr">But that's not all—stay tuned for exciting offers, including exclusive deals from dealerships and perks from our partners. We're committed to eliminating unnecessary costs for both sellers and buyers. As a buyer, you won't need to purchase a report elsewhere; we provide them all for free.</p>
            <p className="descr">For sellers, we offer the most cost-effective listing solution, positioning ourselves as leaders in the market above the most popular platforms. And for dealers, our scalable fleet maintenance solutions are all-inclusive. Reach out to us at <a href="mailto:contact@wakaonline.nz">contact@wakaonline.nz</a> to explore how we can elevate your dealership experience.</p> */}
            {/* <p className="descr">Waka Online NZ — where confidence meets convenience in your car-buying journey.</p> */}
          </div>

          {/* banner Card for small screens */}
          {/* {!isScreenLarge && (
            <Card className={`featured-banner ${isScreenLarge ? '' : 'hide-on-small-screen'}`}>
              {featuredDealer &&
                <div className="slideshow-container">
                  {featuredDealer.map((quote, index) => (
                    <div key={index} className={`mySlides ${index + 1 === slideIndex ? 'active' : ''}`}>
                      <div className="d-flex justify-content-center flex-row" onClick={handleCardClick}>
                        <div className="left-grid d-flex justify-content-end" style={{ marginRight: '5px' }}>
                          {featuredDealer.LogoURL && <img src={featuredDealer.LogoURL} alt="Logo" className="logo" />}
                        </div>
                        <div className="right-grid d-flex flex-column align-items-start" style={{ marginLeft: '5px' }}>
                          <div className="featured-dealer">Featured Dealer</div>
                          <div className="company-name">{quote.text}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </Card>
          )} */}

          {/* banner Card for small screens */}
          <div >
            {!isScreenLarge && (
              <Card className={`featured-banner`}>
                {featuredDealer &&
                  <div className="d-flex justify-content-center flex-row" onClick={handleCardClick}>
                    <div className="left-grid d-flex justify-content-end" style={{ marginRight: '5px' }}>
                      {featuredDealer.LogoURL && <img src={featuredDealer.LogoURL} alt="Logo" className="logo" />}
                    </div>
                    <div className="right-grid d-flex flex-column align-items-start" style={{ marginLeft: '5px' }}>
                      <div className="featured-dealer">Featured Dealers</div>
                      <div className="company-name">{featuredDealer.CompanyName}</div>
                    </div>
                  </div>
                }
              </Card>
            )}
          </div>


          <div className="input-areas">
            <div className="input-area-card">
              <form>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px' }}>
                  <div className='input-container'>
                    <input
                      className="footer-input"
                      name="data"
                      type="text"
                      placeholder="Enter Plate Number or VIN"
                      onChange={(e) => setPlateNumberOrVIN(e.target.value)}
                      disabled={!recaptchaToken || selectedMake || selectedModel}
                      style={{ marginTop: '10px' }}
                    />
                    <span className='or-text'>OR</span>
                    <MakeModelDropdown
                      selectedMake={selectedMake}
                      setSelectedMake={(make) => {
                        setSelectedMake(make);
                      }}
                      selectedModel={selectedModel}
                      setSelectedModel={(model) => {
                        setSelectedModel(model);
                      }}
                      disabled={!recaptchaToken || plateNumberOrVIN.length > 0}
                    />
                  </div>
                  {isWidgetVisible && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div className="cf-turnstile" data-sitekey="0x4AAAAAAACzw6hd6iqcTkiE" data-sitesecret="0x4AAAAAAACzwyOOCiQ862UJS4fc1WBelk8" data-callback="handleCaptchaSuccess"></div>
                    </div>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'center', width: '300px' }}>
                    <Button className="btn btn-danger" onClick={dataSend}>
                      <i className="fas fa-search" /> &nbsp; Search
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* Modal Section for response*/}
          <Modal show={showFalse} onHide={() => { handleCloseFalse(); setExpiredClaimModalShown('true'); }}>
            <Modal.Header closeButton>
              <Modal.Title>{modelMessage}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table style={{ paddingLeft: '5%' }}>
                <tbody>
                  <tr>
                    <th className="text-th">Vehicle plate or VIN numbers</th>
                  </tr>
                  {expiredClaims.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <p id="text-p" className="text-muted mb-0" key={index}>
                          {item.PlateNumber || item.VIN}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => { handleCloseFalse(); setExpiredClaimModalShown('true'); }}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <NicknameModal userID={userID} nickname={user} />
          {/* <div className="center video" style={{ width: '100%', height: '50vh' }} id="youtubePlayer"></div> */}

          {/* banner Card for large screens */}
          {/* {isScreenLarge && (
            <Card className={`featured-banner ${isScreenLarge ? '' : 'hide-on-small-screen'}`}>
              {featuredDealer &&
                <div className="slideshow-container">
                  {featuredDealer.map((quote, index) => (
                    <div key={index} className={`mySlides ${index + 1 === slideIndex ? 'active' : ''}`}>
                      <div className="d-flex justify-content-center flex-row" onClick={handleCardClick}>
                        <div className="left-grid d-flex justify-content-end" style={{ marginRight: '5px' }}>
                          {featuredDealer.LogoURL && <img src={featuredDealer.LogoURL} alt="Logo" className="logo" />}
                        </div>
                        <div className="right-grid d-flex flex-column align-items-start" style={{ marginLeft: '5px' }}>
                          <div className="featured-dealer">Featured Dealer</div>
                          <div className="company-name">{quote.text}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              }
            </Card>
          )} */}

          {/* banner Card for large screens */}
          <div>
            {isScreenLarge && (
              <Card className={`featured-banner ${isScreenLarge ? '' : 'hide-on-small-screen'}`} >
                {featuredDealer &&
                  <div className=" d-flex justify-content-center flex-row" onClick={handleCardClick}>
                    <div className="left-grid d-flex justify-content-end" style={{ marginRight: '5px' }}>
                      {featuredDealer.LogoURL && <img src={featuredDealer.LogoURL} alt="Logo" className="logo" />}
                    </div>
                    <div className="right-grid d-flex flex-column align-items-start" style={{ marginLeft: '5px' }}>
                      <div className="featured-dealer">Featured Dealers</div>
                      <div className="company-name">{featuredDealer.CompanyName}</div>
                    </div>
                  </div>
                }
              </Card>
            )}
          </div>

          <div className="row col-12 md-12 midCol">
            <p className="descr3">But that's not all—stay tuned for exciting offers, including exclusive deals from dealerships and perks from our partners. We're committed to eliminating unnecessary costs for both sellers and buyers. As a buyer, you won't need to purchase a report elsewhere; we provide them all for free.</p>
            <p className="descr3">For sellers, we offer the most cost-effective listing solution, positioning ourselves as leaders in the market above the most popular platforms. And for dealers, our scalable fleet maintenance solutions are all-inclusive. Reach out to us at <a href="mailto:contact@wakaonline.nz">contact@wakaonline.nz</a> to explore how we can elevate your dealership experience.</p>
            <p className="descr2">Waka Online NZ — where confidence meets convenience in your car-buying journey.</p>
          </div>

        </div>

        <div className="col-sm md-12 col-lg-2 imagesz" style={{ whiteSpace: 'nowrap' }}>
          <picture className="poster-image">
            <source media="(min-width: 992px)" srcSet={Poster5} style={{ width: '100%', height: '100vh', margin: '0px 0px 5px 0px', borderRadius: '10px' }} />
            <source media="(max-width: 993px)" srcSet={Cover1} style={{ width: '100%', height: 'auto', margin: '0px 0px 5px 0px', borderRadius: '10px' }} />
            <img src={Cover2} alt="IfItDoesntMatchAnyMedia" />
          </picture>
        </div>
      </div>

      <div className="social-icons">
        <a href="https://www.facebook.com/wakaonlinenz" target="_blank" className="social-icon"><i className="fab fa-facebook-f"></i></a>
        <a href="https://twitter.com/wakaonlinenz" target="_blank" className="social-icon"><i className="fab fa-twitter"></i></a>
        <a href="https://www.linkedin.com/company/wakaonlinenz/?originalSubdomain=nz" target="_blank" className="social-icon"><i className="fab fa-linkedin-in"></i></a>
        <a href="https://www.youtube.com/channel/UCLy-5NbaUWzFveI_IIuLFug" target="_blank" className="social-icon"><i className="fab fa-youtube"></i></a>
      </div>

      <Chatbot />
      <div className="featuredvehicels">
        <FeaturedVehicles />
      </div>
      <div className="importedVehicles">
        <ImportedVehicles />
      </div>
    </div>
  );
}

export default HeroSection;
