function performRedirect(plateNumber, VIN, previousLocation, setPreviousLocation, navigate) {
    if (previousLocation === '/details') {
        if (!plateNumber && !VIN) {
            navigate('/', { replace: true });
        } else {
            const plateOrvin = plateNumber ? `plate=${plateNumber}` : `vin=${VIN}`;
            navigate('/details', {
                replace: true,
                state: { plateOrvin }
            });
        }
        setPreviousLocation('');
    } else {
        navigate('/', { replace: true });
    }
}

export { performRedirect };
